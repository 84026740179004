/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

/* 
This files function(s) is to provide a hook that fetches data from the backend and returns it to the component that calls it. 
This break out of the query(s) is needed to make the Apollos graphql queries work properly with caching in-between the micro frontend apps.
*/

import { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';

interface FooterData {
  header: string; // title
  name: string; // link
}

export function useDynamicLinks(): FooterData[] {
  const [dynamicLinks, setDynamicLinks] = useState<FooterData[]>([]);

  const { loading, error, data } = useQuery(
    gql`
      query Query($isFrontPage: Boolean) {
        engages(isFrontPage: $isFrontPage) {
          header
          name
        }
      }
    `,
    {
      variables: {
        isFrontPage: false
      }
    }
  );

  useEffect(() => {
    if (loading) {
      
    } else if (error) {
      console.error('Error:', error.message);
    } else if (data) {
      const formattedEngages = data.engages.map((item: FooterData) => ({
        name: '/engage-details/' + item.name,
        header: item.header
      }));
      setDynamicLinks(formattedEngages);
    }
  }, [loading, error, data]);

  return dynamicLinks;
}
